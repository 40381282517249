import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import styled, { keyframes } from "styled-components"
import { BgImage } from "gbimage-bridge"
import ContentDiv from "./contentDiv"
import PageSection from "./pageSection"
import Text, { TextType } from "./text"
import { slideInLeft } from "react-animations"
import { laptop, tablet } from "../util/mediaQueries"

interface Props {
  headerText: string
  children: React.ReactNode
  scaling?: number
  centered?: boolean
}

const RootDiv = styled(BgImage)`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: ${props => props.backgroundHeight}px;
  background-attachment: scroll;
  @media ${tablet} {
    height: ${props => props.backgroundHeight}px;
    background-attachment: fixed;
  }
  text-align: center;
  background-size: ${props => `${props.backgroundWidth}px ${props.backgroundHeight}px`};
`

const slideAnimation = keyframes`${slideInLeft}`

const AnimationDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-left: 20px;
  @media ${laptop} {
    margin-right: 0;
    margin-left: 0;
  }
  ${props => props.centered &&
    "justify-content: center;"
  }
  animation: 1s ${slideAnimation};
`

const HeaderDiv = styled.div`
  width: 100%;
  text-align: center;
  hyphens: none;
  margin-bottom: 20px;
  @media ${tablet} {
    margin-bottom: 50px;
  }
`

const getSize = (scale: number) => [Math.round(172 * scale), Math.round(210 * scale)]

const FirstSection = (props: Props) => {
  const { image } = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "ampersand.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  const backgroundImage = getImage(image)

  const [backWidth, backHeight] = props.scaling ? getSize(props.scaling) : getSize(1)

  return (
    <PageSection>
      <ContentDiv>
        <RootDiv image={backgroundImage} backgroundWidth={backWidth} backgroundHeight={backHeight} >
          <AnimationDiv centered={props.centered} >
            <HeaderDiv>
              <Text type={TextType.Header}>{props.headerText}</Text>
            </HeaderDiv>
            <Text type={TextType.Body}>{props.children}</Text>
          </AnimationDiv>
        </RootDiv>
      </ContentDiv>
    </PageSection>
  )
}

export default FirstSection
